var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ranks bg-white w-100 h-100"},[_c('div',{staticClass:"header bg-white text-center flex align-center font-regular"},[_c('span',{staticClass:"football pointer flex-1 item font-14 font-400",class:{'is-active': _vm.leagueType === 1},on:{"click":function($event){return _vm.changeType(1)}}},[_vm._v("足球积分榜")]),_c('span',{staticClass:"basketball pointer flex-1 item font-14 font-400",class:{'is-active': _vm.leagueType === 2},on:{"click":function($event){return _vm.changeType(2)}}},[_vm._v("篮球积分榜")])]),_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"types m-l-10 m-r-10 flex p-l-10 p-r-10 align-center justify-between"},_vm._l((_vm.showOptions),function(type){return _c('div',{key:type.id,staticClass:"type font-14 pointer flex-1 text-center",class:{
                    'is-active': type.id === _vm.activeId,
                    'disabled not-allowed': type.disable,
                    'no-border': !_vm.isFootball
                 },on:{"click":function($event){return _vm.changeTab(type)}}},[_vm._v(_vm._s(type.label))])}),0),(_vm.showSecondTab)?_c('div',{staticClass:"section-tabs m-t-5 types m-l-10 m-r-10 flex p-l-10 p-r-10 align-center justify-between"},_vm._l((_vm.sectionTabs),function(type){return _c('div',{key:type.id,staticClass:"type font-16 pointer flex-1 text-center",class:{
                    'is-active': type.id === _vm.sectionTabId
                 },on:{"click":function($event){return _vm.changeSectionTab(type)}}},[_vm._v(_vm._s(type.title))])}),0):_vm._e()]),_c('div',{staticClass:"tables w-100 bg-white",class:{
        'has-second': _vm.showSecondTab
    }},[_c('div',{staticClass:"table-box"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"custom-table p-l-5 p-r-5",attrs:{"data":_vm.list,"height":"100%","fit":"","align":"center"}},[_c('el-table-column',{attrs:{"prop":"rank","label":"排名","align":"center","width":"50"}}),_c('el-table-column',{attrs:{"prop":"team","label":"球队","align":"center","min-width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"flex w-100 align-center"},[_c('span',{directives:[{name:"lazy-container",rawName:"v-lazy-container",value:({ selector: 'img' }),expression:"{ selector: 'img' }"}],staticClass:"team-icon flex align-center justify-center flex-shrank-0 border-radius-50"},[_c('img',{staticClass:"w-100 h-100",attrs:{"data-src":scope.row.flag,"data-error":_vm.errorTeamLogo,"alt":""}})]),_c('span',{staticClass:"m-l-5 score-span text-left"},[_c('custom-span',{staticClass:"w-100 text-left",attrs:{"content":scope.row.nameChs}})],1)])]}}])}),(_vm.isFootball)?[_c('el-table-column',{attrs:{"prop":"score","label":"胜/负/平","align":"center","min-width":"50"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(" "+_vm._s(scope.row.winCount)+" / "+_vm._s(scope.row.loseCount)+" / "+_vm._s(scope.row.drawCount)+" ")])]}}],null,false,3588730167)}),_c('el-table-column',{attrs:{"prop":"integral","label":"积分","align":"center","min-width":"30"}})]:[_c('el-table-column',{attrs:{"prop":"win","label":"胜","align":"center","min-width":"25"}}),_c('el-table-column',{attrs:{"prop":"lose","label":"负","align":"center","min-width":"25"}}),_c('el-table-column',{attrs:{"prop":"winRate","label":"胜率","align":"center","min-width":"30"}})]],2)],1),_c('div',{staticClass:"update-time text-center font-12 font-regular"},[_vm._v(" 数据更新于："+_vm._s(_vm.updateTime)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }